var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"file-tool-bar grey lighten-2",attrs:{"align":"center","no-gutters":""}},[(_vm.roleS3SelectedFileNode.create)?[(_vm.isFolder)?_c('AddFolderDialog',{attrs:{"parent":_vm.selectedFileNode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" $vuetify.icons.add-folder ")]),(
              !_vm.selectedFileNode ||
                _vm.selectedFileNode === _vm.fileTree.root
            )?_c('span',[_vm._v(_vm._s(_vm.$t('page.files.ceate_folder')))]):_c('span',[_vm._v(_vm._s(_vm.$t('page.files.ceate_mapset')))])],1)]}}],null,false,2148932802)}):(
        _vm.selectedFileNode &&
          _vm.selectedFileNode.type===_vm.FILE_TYPE.MAPSET
      )?_c('UploadMultiFilesDialog',{attrs:{"parent":_vm.selectedFileNode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedFileNode ||
              _vm.selectedFileNode.type!== _vm.FILE_TYPE.MAPSET,"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-upload ")]),_vm._v(" "+_vm._s(_vm.$t('page.files.upload_maplayer_title'))+" ")],1)]}}],null,false,1304364562)}):_vm._e()]:_vm._e(),(_vm.roleS3ActiveFileNode.update)?[_c('v-divider',{attrs:{"vertical":""}}),_c('v-divider',{attrs:{"vertical":""}}),_c('RenameFileDialog',{attrs:{"file-node":_vm.activeFileNode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabledActiveActionBtn,"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rename-box")]),_vm._v(" "+_vm._s(_vm.$t('rename'))+" ")],1)]}}],null,false,651546159)})]:_vm._e(),(_vm.roleS3ActiveFileNode.delete)?[_c('v-divider',{attrs:{"vertical":""}}),_c('DeleteFileDialog',{attrs:{"file-node":_vm.activeFileNode},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabledActiveActionBtn,"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")],1)]}}],null,false,484666391)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }