<template>
  <v-breadcrumbs
    v-if="breadcrumbs.length > 1"
    :items="breadcrumbs"
  >
    <template v-slot:divider>
      <slot name="divider">
        {{ divider }}
      </slot>
    </template>
    <template v-slot:item="{ item }">
      <v-btn
        v-if="item.goBack"
        :disabled="item.disabled"
        :title="item.name"
        color="primary"
        icon
        style="text-transform: none;"
        @click="selectNode(selectedFileNode.parent)"
      >
        <v-icon
          class="mr-2"
          small
        >
          mdi-undo-variant
        </v-icon>
      </v-btn>
      <v-btn
        v-else-if="!item.disabled"
        :disabled="item.disabled"
        :title="item.name"
        color="primary"
        text
        style="text-transform: none;"
        @click="selectNode(item.node)"
      >
        <v-icon
          class="mr-2"
          small
        >
          {{ `$vuetify.icons.${item.node.fileType}` }}
        </v-icon>
        {{ item.text }}
      </v-btn>
      <v-row
        v-else
        class="
          current-file-text
          grey--text
          text--darken-4
          px-4
        "
        :title="item.name"
        no-gutters
        justify="center"
        align="center"
      >
        <v-icon
          class="mr-2"
          small
        >
          {{ `$vuetify.icons.${item.node.fileType}` }}
        </v-icon>
        {{ item.text }}
      </v-row>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { FILE_TYPE } from '@/models/utils'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FileBreadcrumbs',

  props: {
    divider: {
      type: String,
      default: '/'
    }
  },

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      selectedFileNode: state => state.files.selectedFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },

    root() {
      return this.fileTree?.root
    },

    breadcrumbs() {
      const breadcrumbs = [
        { // 回上層資料夾
          name: this.$t('go_back_to_prev_level'),
          goBack: true,
          disabled: true
        }
      ]

      if (!this.selectedFileNode) {
        return breadcrumbs
      }

      let parent = this.selectedFileNode
      while (parent) {
        if (parent.level > 0) {
          breadcrumbs.splice(1, 0, parent)
        }

        parent = parent.parent
      }

      return breadcrumbs.map((breadcrumb, iBreadcrumb) => {
        const disabled = iBreadcrumb === breadcrumbs.length - 1

        if (breadcrumb.goBack) {
          return {
            ...breadcrumb,
            disabled
          }
        }

        let text = breadcrumb.name
        if (text.length > 12) {
          text = `${text.slice(0, 6)}...${text.slice(-6)}`
        }

        return {
          text,
          name: breadcrumb.name,
          disabled,
          node: breadcrumb
        }
      })
    }
  },

  methods: {
    fetchChildren(parentNode) {
      // 不指定parentNode就是fetch資料夾
      const parent = parentNode || this.root
      const project = this.project
      return this.$store.dispatch('files/fetchFileNodes', {
        project,
        parent
      })
        .catch(error => this.$store.dispatch('snackbar/showError', {
          content: error
        }))
    },
    selectNode(fileNode) {
      this.$store.dispatch('files/selectFileNode', {
        fileNode
      })
      this.$store.dispatch('files/activeFileNode', {
        fileNode,
        open: true
      })

      if (
        fileNode.hasLoaded && // FileTree load過才需要幫忙reload
        fileNode.type !== FILE_TYPE.FILE
      ) {
        this.fetchChildren(fileNode)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.current-file-text {
  min-width: 64px;

  // 為了讓text和v-btn的style相符
  height: 36px;
  font-size: 0.875rem;
  font-weight: 500;
  text-indent: 0.0892857143em;
  white-space: nowrap;
  flex: 0 0 auto;
  letter-spacing: 0.0892857143em;
  vertical-align: middle;
}
</style>
