<template>
  <v-card
    outlined
    height="100%"
    style="overflow: auto; border: none;"
  >
    <v-card-title class="pa-0 justify-center">
      <h3>
        {{ $t('page.projects.manage_folder_title') }}
      </h3>
    </v-card-title>

    <v-divider class="my-3" />

    <!-- tip team permission -->
    <v-row no-gutters justify="center">
      <p class="mb-4" style="font-size: 0.875rem;">
        {{ $t('page.projects.manage_folder_content') }}
      </p>
    </v-row>

    <!-- selected database name -->
    <v-row
      v-if="selectedFolderNode"
      class="mb-5 primary--text"
      no-gutters
      justify="center"
      style="font-size: 1.125rem;"
    >
      <v-col
        class="text-truncate"
        :title="selectedFolderNode.name"
        cols="auto"
      >
        {{ selectedFolderNode.name }}
      </v-col>
    </v-row>

    <!-- team list -->
    <template v-if="selectedFolderNode">
      <v-card
        color="transparent"
        flat
        style=" overflow-y: auto;height: calc(100% - 190px);"
      >
        <v-row
          v-for="(team, iTeam) in selectedFolderTeams"
          :key="`${selectedFolderTeams.name}-${iTeam}`"
          class="mb-2"
          no-gutters
        >
          <TeamRole v-model="team.role" :team="team" />
        </v-row>
      </v-card>

      <v-btn
        class="add-to-project-btn px-6 py-1"
        :color="isJoined ? 'error' : 'primary'"
        rounded
        outlined
        small
        @click="toggleJoinFolder()"
      >
        <span v-if="!isJoined">
          {{ $t('join_to_project') }}
        </span>
        <span v-else>
          {{ $t('cancel_joined') }}
        </span>
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import TeamRole from './TeamRole'

import { cloneDeep } from 'lodash'

import { FILE_TYPE } from '@/models/utils'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ProjectJoinedFolderPermission',

  components: {
    TeamRole
  },

  data: () => ({
    defaultTeams: []
  }),

  computed: {
    ...mapState({
      selectedFileNode: state => state.files.selectedFileNode,
      activeFileNode: state => state.files.activeFileNode,
      formData: state => state.projects.form.formData,
      defaultRole: state => state.projects.form.defaultRole
    }),
    ...mapGetters({
      getSubTeamByFormTeam: 'projects/form/getSubTeamByFormTeam',
      getDefaultSubTeams: 'projects/form/getDefaultSubTeams'
    }),

    selectedFolderNode() {
      let selectedFolderNode = null

      let fileNode = this.activeFileNode
      while (fileNode) {
        if (fileNode.type === FILE_TYPE.FOLDER) {
          selectedFolderNode = fileNode
          break
        }

        fileNode = fileNode.parent
      }

      return selectedFolderNode
    },
    formFolders() {
      return this.formData.folders
    },
    formTeams() {
      return this.formData.teams.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    isJoined() {
      return this.formFolders?.some(fFolder => fFolder.resourceUuid === this.selectedFolderNode.uuid)
    },
    selectedFolderTeams() {
      if (this.isJoined) {
        return this.formFolders.find(fFolder => fFolder.resourceUuid === this.selectedFolderNode.uuid)?.teams || []
      }

      return this.defaultTeams
    }
  },

  watch: {
    formTeams: {
      handler() {
        this.initDefaultTeams()
        this.updateFormFolderTeams()
      },
      immediate: true
    }
  },

  methods: {
    initDefaultTeams() {
      this.defaultTeams = cloneDeep(this.getDefaultSubTeams)
    },
    toggleJoinFolder() {
      if (this.isJoined) {
        this.$store.dispatch('projects/form/setFormData', {
          folders: this.formFolders.filter(fFolder => fFolder.resourceUuid !== this.selectedFolderNode.uuid)
        })

        return
      }

      this.$store.dispatch('projects/form/setFormData', {
        folders: [
          ...this.formFolders,
          {
            resourceUuid: this.selectedFolderNode.uuid,
            teams: cloneDeep(this.defaultTeams)
          }
        ]
      })
      this.initDefaultTeams()
    },
    updateFormFolderTeams() {
      this.$store.dispatch('projects/form/setFormData', {
        folders: this.formFolders.map(fFolder => ({
          ...fFolder,
          teams: this.defaultTeams.map(defaultTeam => {
            const curTeam = fFolder.teams.find(team => team.name === defaultTeam.name)

            return {
              ...defaultTeam,
              role: curTeam?.role || defaultTeam.role
            }
          })
        }))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.step-col {
  border: solid 0.8px #ddd;
}

.add-to-project-btn {
  position: absolute;
  right: 30px;
  bottom: 22px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2) !important;
}
</style>
