var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"overflow":"auto"},attrs:{"loading":_vm.isLoadingFolders,"outlined":"","height":"100%"}},[(!_vm.isDashboardModel && _vm.roleS3.create)?_c('v-card-text',{staticClass:"flex-grow-0"},[_c('AddFolderDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","block":"","depressed":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('page.files.add_folder_title'))+" ")])]}}],null,false,785739678)}),_c('v-divider',{staticClass:"my-4"})],1):_vm._e(),_c('v-card-text',{staticClass:"flex-grow-1 pa-0",class:{
      'mt-6': _vm.isDashboardModel || !_vm.roleS3.create
    },staticStyle:{"overflow-x":"auto"}},[_c('v-treeview',{ref:"tree",staticClass:"file-treeview ml-n2",attrs:{"load-children":_vm.fetchChildren,"items":_vm.folderNodes,"open":_vm.openNodes,"active":_vm.activeFileNode,"expand-icon":'',"item-key":"uuid","open-on-click":"","return-object":"","hoverable":"","dense":""},on:{"update:open":function($event){_vm.openNodes=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
    var item = ref.item;
    var open = ref.open;
return [(item.type === _vm.FILE_TYPE.FILE)?_c('v-icon',{attrs:{"color":_vm.isFileNodeJoined(item) ? 'secondary' : 'primary'},on:{"click":function($event){return _vm.onClickTreeItem(item)}}},[_vm._v(" "+_vm._s(item.maplayerType === 'SETTINGS_FILE' ? '$vuetify.icons.settings-file' : _vm.$vuetify.icons.values[item.fileType] ? ("$vuetify.icons." + (item.fileType)) : '$vuetify.icons.unknown')+" ")]):(item.type === _vm.FILE_TYPE.FOLDER && _vm.isFileNodeJoined(item))?_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.onClickTreeItem(item)}}},[_vm._v(" $vuetify.icons.joined-folder ")]):_c('v-icon',{attrs:{"color":_vm.isFileNodeJoined(item) ? 'secondary' : 'primary'},on:{"click":function($event){return _vm.onClickTreeItem(item)}}},[_vm._v(" "+_vm._s(open ? item.children.length ? '$folder-open' : '$folder-open-empty' : '$folder')+" ")])]}},{key:"label",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{staticClass:"v-treeview-node__label__content flex-nowrap",attrs:{"no-gutters":"","align":"center","title":item.name},on:{"click":function($event){return _vm.onClickTreeItem(item)}}},[(
              _vm.isProjectModel &&
                item.type !== _vm.FILE_TYPE.FOLDER &&
                _vm.isFileNodeJoined(item) &&
                !_vm.hasResource(item.uuid)
            )?_c('v-col',{staticClass:"mr-1",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.files.not_yet_join_project')))])])],1):_vm._e(),(
              _vm.isFileNodeJoined(item) &&
                (item.type === _vm.FILE_TYPE.FOLDER || _vm.isDashboardModel)
            )?_c('v-col',{staticClass:"mr-1",attrs:{"cols":"auto"}},[_vm._v(" ("+_vm._s(_vm.$t('joined'))+") ")]):_vm._e(),_c('v-col',[_vm._v(_vm._s(item.name))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }