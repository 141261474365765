var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"640"},on:{"click:outside":_vm.cancel},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","disabled":!_vm.fileNode,"small":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('page.files.file_settings_title'))+" ")])]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.fileNode)?_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',{staticClass:"draggable"},[_c('v-row',{staticClass:"grey--text text--darken-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('page.files.file_settings_title'))+" ")]),_c('v-col',{staticClass:"primary--text",staticStyle:{"margin-left":"0.5em"},attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.fileNode.name)+" ")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"readonly":_vm.readonly || !_vm.roleS3.update,"disabled":_vm.isLoading}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('key_name'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('key_value'))+" ")])])]),_c('tbody',[(
              Object.prototype.hasOwnProperty
                .call(_vm.editSettings, _vm.D3_CENTER.key)
            )?_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('3d_model_center_coordinate'))+" ")]),_c('td',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('longitude'),"dense":"","rules":[
                        _vm.validateRequired
                      ]},model:{value:(_vm.editSettings[_vm.D3_CENTER.key][0]),callback:function ($$v) {_vm.$set(_vm.editSettings[_vm.D3_CENTER.key], 0, $$v)},expression:"editSettings[D3_CENTER.key][0]"}})],1),_c('v-col',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('latitude'),"dense":"","rules":[
                        _vm.validateRequired
                      ]},model:{value:(_vm.editSettings[_vm.D3_CENTER.key][1]),callback:function ($$v) {_vm.$set(_vm.editSettings[_vm.D3_CENTER.key], 1, $$v)},expression:"editSettings[D3_CENTER.key][1]"}})],1)],1)],1)]):_vm._e(),(
              Object.prototype.hasOwnProperty
                .call(_vm.editSettings, _vm.SKY_AXIS.key)
            )?_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('3d_model_sky_axis'))+" ")]),_c('td',[_c('v-radio-group',{attrs:{"row":"","dense":"","rules":[
                    _vm.validateRequired
                  ]},model:{value:(_vm.editSettings[_vm.SKY_AXIS.key]),callback:function ($$v) {_vm.$set(_vm.editSettings, _vm.SKY_AXIS.key, $$v)},expression:"editSettings[SKY_AXIS.key]"}},_vm._l((_vm.SKY_AXIS.options),function(axis){return _c('v-radio',{key:("" + (axis.value)),attrs:{"label":axis.label,"value":axis.value}})}),1)],1)]):_vm._e()])])],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.readonly || !_vm.roleS3.update)?_c('v-btn',{staticClass:"action-btn",attrs:{"rounded":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]):[_c('v-btn',{staticClass:"action-btn",attrs:{"color":"primary","rounded":"","disabled":_vm.isLoading,"loading":_vm.isSending},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{staticClass:"action-btn ml-3",attrs:{"rounded":"","disabled":_vm.isLoading},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }