<template>
  <v-dialog
    v-model="dialog"
    max-width="640"
    @click:outside="cancel"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          color="secondary"
          :disabled="!fileNode"
          small
          text
          v-on="on"
        >
          {{ $t('page.files.file_settings_title') }}
        </v-btn>
      </slot>
    </template>
    <v-card
      v-if="fileNode"
      :loading="isLoading"
    >
      <v-card-title class="draggable">
        <v-row
          class="grey--text text--darken-4"
          no-gutters
        >
          <v-col cols="auto">
            {{ $t('page.files.file_settings_title') }}
          </v-col>
          <v-col
            class="primary--text"
            cols="auto"
            style="margin-left: 0.5em;"
          >
            {{ fileNode.name }}
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          :readonly="readonly || !roleS3.update"
          :disabled="isLoading"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th>
                  {{ $t('key_name') }}
                </th>
                <th>
                  {{ $t('key_value') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="
                Object.prototype.hasOwnProperty
                  .call(editSettings, D3_CENTER.key)
              "
              >
                <td>
                  {{ $t('3d_model_center_coordinate') }}
                </td>
                <td>
                  <v-row>
                    <v-col class="pb-0">
                      <v-text-field
                        v-model="editSettings[D3_CENTER.key][0]"
                        type="number"
                        :label="$t('longitude')"
                        dense
                        :rules="[
                          validateRequired
                        ]"
                      />
                    </v-col>
                    <v-col class="pb-0">
                      <v-text-field
                        v-model="editSettings[D3_CENTER.key][1]"
                        type="number"
                        :label="$t('latitude')"
                        dense
                        :rules="[
                          validateRequired
                        ]"
                      />
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <tr v-if="
                Object.prototype.hasOwnProperty
                  .call(editSettings, SKY_AXIS.key)
              "
              >
                <td>
                  {{ $t('3d_model_sky_axis') }}
                </td>
                <td>
                  <v-radio-group
                    v-model="editSettings[SKY_AXIS.key]"
                    row
                    dense
                    :rules="[
                      validateRequired
                    ]"
                  >
                    <v-radio
                      v-for="axis in SKY_AXIS.options"
                      :key="`${axis.value}`"
                      :label="axis.label"
                      :value="axis.value"
                    />
                  </v-radio-group>
                </td>
              </tr>

              <!-- <template v-if="editSettings.plot">
                <tr v-for="(pVal,pKey) in editSettings.plot" :key="pKey">
                  <td>
                    {{ pKey }}
                  </td>
                  <td>
                    {{ pVal }}
                  </td>
                </tr>
              </template> -->
            </tbody>
          </v-simple-table>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          v-if="readonly || !roleS3.update"
          class="action-btn"
          rounded
          @click="cancel"
        >
          {{ $t('close') }}
        </v-btn>
        <template v-else>
          <v-btn
            class="action-btn"
            color="primary"
            rounded
            :disabled="isLoading"
            :loading="isSending"
            @click="submit"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            class="action-btn ml-3"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { roleRoot } from '@/store/modules/files/files'
import { FileNode } from '@/models'
import { SETTINGS_KEYS } from '@/models/utils'
import { validateRequired } from '@/assets/js/validate'

import { cloneDeep } from 'lodash'

import { mapGetters } from 'vuex'

export default {
  name: 'SettingsDialog',

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    fileNode: { // 要處理的fileNode, 沒給就disabled
      type: FileNode,
      default: null
    },
    isNewFile: { // 給上傳檔案時, 預先設定settings使用, 此時進來的fileNode只是暫時的fileNode
      type: Boolean,
      default: false
    }
  },

  data: vm => ({
    dialog: false,
    name: vm.fileNode?.name || '',
    editSettings: vm.formatEditSettings(),
    isSending: false,
    validateRequired,
    ...SETTINGS_KEYS
  }),

  computed: {
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },

    isLoading() {
      return this.isSending
    },

    roleS3() {
      if (!this.project ||
       this.project.isOwner ||
       this.isNewFile
      ) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.fileNode?.uuid) || {}
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    },
    dialog(newVal) {
      if (newVal && this.fileNode) {
        this.editSettings = this.formatEditSettings(this.fileNode.settings)
        // this.readFileSettings()
        this.$nextTick().then(() => {
          this.$refs.form.validate()
        })
      }
    }
  },

  methods: {
    init() {
      this.editSettings = this.formatEditSettings()

      if (this.fileNode) {
        this.$refs.form.resetValidation()
      }
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()

      this.init()
    },
    // copy3dCenterToFlyToCenter() {
    //   const mD3Center = this.editSettings[this.D3_CENTER.key]

    //   this.editSettings[this.FLYTO_CENTER.key] = [...mD3Center]
    // },
    // formatZoom(e) {
    //   const val = parseFloat(e.target.value)
    //   if (val > 22) {
    //     this.editSettings[this.FLYTO_ZOOM.key] = 22
    //   }
    //   if (val < 0) {
    //     this.editSettings[this.FLYTO_ZOOM.key] = 0
    //   }
    // },
    formatEditSettings(settings = {}) {
      const editSettings = Object.assign({}, Object.fromEntries(
        Object.keys(this.fileNode.settings).map(key => {
          const value = cloneDeep(settings[key] || this.fileNode.settings[key])

          return [key, value]
        })
      ))

      return editSettings
    },
    // readFileSettings() {
    //   this.editSettings = this.formatEditSettings(this.fileNode.settings)

    //   this.$nextTick().then(() => {
    //     this.$refs.form.validate()
    //   })
    // },
    submit() {
      if (!this.$refs.form.validate()) {
        return this.$store.dispatch('snackbar/showError', {
          content: this.$t('validate.form_incomplete')
        })
      }

      if (this.isNewFile) {
        this.fileNode.readSettings(this.editSettings)

        this.cancel()

        return
      }

      this.isSending = true
      return this.$store.dispatch('files/updateFileSettings', {
        project: this.project,
        fileNode: this.fileNode,
        settings: this.editSettings
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_setted')
          })

          this.cancel()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
