<template>
  <v-card
    :loading="isLoading === true"
    outlined
    style=" overflow-y: auto;border: none;"
  >
    <v-btn
      color="primary"
      :disabled="
        isLoading===true||
          (root && root.isLoading===true)
      "
      icon
      small
      style="position: absolute; top: 8px; right: 12px; z-index: 1;"
      @click="refreshChildren(selectedFileNode||root)"
    >
      <v-icon small>
        mdi-refresh
      </v-icon>
    </v-btn>

    <v-row v-if="typeof isLoading === 'string'"
           no-gutters
           justify="center"
           align="center"
           style="height: 100%;"
    >
      <IssueCard
        error
        img-width="40%"
        :message="isLoading"
        style="width: 100%;"
      >
        <template v-slot:message>
          <v-row no-gutters>
            <v-col
              class="d-flex justify-center align-center mb-4"
              cols="12"
            >
              {{ isLoading }}
            </v-col>
            <v-col
              class="d-flex justify-center align-center"
              cols="12"
            >
              <v-btn
                class="text-decoration-underline"
                color="primary"
                text
                :loading="isLoading===true"
                @click="selectFileNode(selectedFileNode)"
              >
                {{ $t('reload') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </IssueCard>
    </v-row>
    <FileIssueCard v-else-if="!hasChildrenNodes && isLoading === false">
      <v-row justify="center">
        <v-col
          class="d-flex justify-center align-center"
          cols="12"
        >
          <span v-if="selectedFileNode.type===FILE_TYPE.FOLDER">
            <span v-if="
              readonly || !roleS3SelectedFileNode.create
            "
            >{{ $t('page.files.no_mapset') }}</span>
            <span v-else>{{ $t('page.files.no_mapset_and_create') }}</span>
          </span>
          <span v-else-if="selectedFileNode.type===FILE_TYPE.MAPSET">
            <span v-if="
              readonly || !roleS3SelectedFileNode.create
            "
            >{{ $t('page.files.no_file') }}</span>
            <span v-else>{{ $t('page.files.no_file_and_upload') }}</span>
          </span>
        </v-col>
        <v-col
          v-if="!$route.params.projectId || roleS3SelectedFileNode.create"
          class="d-flex justify-center align-center"
          cols="12"
        >
          <v-btn
            v-if="readonly && !project"
            color="primary"
            :to="{name:'FileMgr'}"
          >
            <span v-if="
              !selectedFileNode ||
                selectedFileNode.type === FILE_TYPE.FOLDER
            "
            >{{ $t('go_to_create') }}</span>
            <span
              v-else-if="
                selectedFileNode &&
                  selectedFileNode.type===FILE_TYPE.MAPSET
              "
            >{{ $t('go_to_upload') }}</span>
            <v-icon small right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
          <AddFolderDialog
            v-else-if="!selectedFileNode ||
              selectedFileNode.type === FILE_TYPE.FOLDER
            "
            :parent="selectedFileNode"
          />
          <UploadMultiFilesDialog
            v-else-if="
              selectedFileNode &&
                selectedFileNode.type===FILE_TYPE.MAPSET
            "
            :parent="selectedFileNode"
          />
        </v-col>
      </v-row>
    </FileIssueCard>
    <v-list
      v-else
      class="file-list mt-8"
      dense
    >
      <v-list-item-group
        v-model="activeFileNode"
        color="primary"
      >
        <template
          v-for="(childrenNode, iChildrenNode) in childrenNodes"
        >
          <!--
            FIXME: v-list-item-group的v-model沒對應到v-list-item時
            會$emit('input', undefined)使active失效
            所以給一個不顯示的selectedFileNode在第一個, 避開此issue
          -->
          <v-list-item
            v-show="iChildrenNode"
            :key="`${childrenNode.uuid}-${iChildrenNode}`"
            class="px-5"
            :value="childrenNode"
            :ripple="false"
            @dblclick.native="
              childrenNode.type === FILE_TYPE.FILE
                ? fetchFileData(childrenNode)
                : selectFileNode(childrenNode)
            "
          >
            <v-list-item-icon
              class="mr-5 align-center"
            >
              <v-btn
                v-if="childrenNode.type !== FILE_TYPE.FILE"
                :color="isFileNodeJoined(childrenNode) ? 'secondary': 'primary'"
                icon
                @click="selectFileNode(childrenNode)"
              >
                <v-icon>
                  <template
                    v-if="
                      isFileNodeJoined(childrenNode) &&
                        childrenNode.type===FILE_TYPE.FOLDER
                    "
                  >
                    $vuetify.icons.joined-folder
                  </template>
                  <template v-else>
                    $vuetify.icons.enter-folder
                  </template>
                </v-icon>
              </v-btn>
              <v-icon
                v-else
                :class="{'spin': childrenNode.isUploading}"
                :color="
                  isFileNodeJoined(childrenNode)
                    ? 'secondary'
                    : childrenNode.isDamagedFile
                      ? 'error'
                      :'primary'"
              >
                {{
                  childrenNode.isDamagedFile
                    ?'$vuetify.icons.file-error'
                    : childrenNode.isUploading
                      ? '$vuetify.icons.file-loading'
                      : childrenNode.maplayerType === 'SETTINGS_FILE'
                        ? '$vuetify.icons.settings-file'
                        : $vuetify.icons.values[childrenNode.fileType]
                          ? `$vuetify.icons.${childrenNode.fileType}`
                          : '$vuetify.icons.unknown'
                }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-row
                  no-gutters
                  align="center"
                  :title="childrenNode.name"
                >
                  <!-- 編輯專案時針對新增且尚未加入到專案的檔案做提醒 -->
                  <v-col
                    v-if="
                      isProjectModel &&
                        childrenNode.type!==FILE_TYPE.FOLDER &&
                        isFileNodeJoined(childrenNode) &&
                        !hasResource(childrenNode.uuid)
                    "
                    class="mr-1"
                    cols="auto"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('page.files.not_yet_join_project') }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    v-if="
                      isFileNodeJoined(childrenNode) &&
                        (childrenNode.type===FILE_TYPE.FOLDER || isDashboardModel)
                    "
                    class="mr-2"
                    cols="auto"
                  >
                    ({{ $t('joined') }})
                  </v-col>
                  <v-col
                    class="text-truncate"
                  >
                    <span v-if="childrenNode.type===FILE_TYPE.FILE">{{ childrenNode.name }}</span>
                    <v-hover
                      v-else
                      v-slot="{hover}"
                    >
                      <a
                        :class="{
                          'text-decoration-underline':hover
                        }"
                        @click="selectFileNode(childrenNode)"
                      >{{ childrenNode.name }}</a>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action
              v-if="
                !readonly &&
                  childrenNode.type === FILE_TYPE.FILE
              "
            >
              <v-row no-gutters align="center">
                <v-col
                  v-if="
                    childrenNode.type === FILE_TYPE.FILE &&
                      (childrenNode.isLoading || childrenNode.hasLoaded)
                  "
                  class="
                      d-flex
                      align-center
                      mr-4
                    "
                  cols="auto"
                  style="font-size: 0.6em;"
                >
                  <v-progress-circular
                    v-if="
                      childrenNode.isLoading===true
                    "
                    class="mr-6"
                    color="grey"
                    width="2"
                    size="20"
                    indeterminate
                  />
                  <v-row
                    v-else
                    :class="{
                      'secondary--text': !childrenNode.isLoading,
                      'error--text': typeof childrenNode.isLoading === 'string'
                    }"
                    no-gutters
                    align="center"
                    style="min-width: 8em;"
                  >
                    <v-icon
                      :color="
                        typeof childrenNode.isLoading === 'string'
                          ? 'error'
                          : 'secondary'
                      "
                      small
                    >
                      {{
                        typeof childrenNode.isLoading === 'string'
                          ? 'mdi-close'
                          : 'mdi-check'
                      }}
                    </v-icon>
                    {{
                      typeof childrenNode.isLoading === 'string'
                        ? childrenNode.isLoading
                        : $t('layer_loaded')
                    }}
                  </v-row>
                </v-col>
                <v-col v-visible="!childrenNode.isUploading" cols="auto">
                  <v-btn
                    v-if="!childrenNode.downloadProgress"
                    icon
                    :disabled="childrenNode.isDownloading"
                    :loading="childrenNode.isDownloading"
                    @click="downloadFile(childrenNode)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <span v-else>{{ childrenNode.downloadProgress }}%</span>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="iChildrenNode"
            :key="`${childrenNode.uuid || iChildrenNodes}-divider`"
          />
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import FileIssueCard from '../FileIssueCard'
import AddFolderDialog from '../Functions/ActionDialogs/AddFolderDialog'
import UploadMultiFilesDialog from '../Functions/ActionDialogs/UploadMultiFilesDialog'

import { FILE_TYPE } from '@/models/utils'
import { roleRoot } from '@/store/modules/files/files'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FileList',

  inject: {
    fetchFileContent: {
      from: 'fetchFileContent',
      default: null
    }
  },

  components: {
    IssueCard,
    FileIssueCard,
    AddFolderDialog,
    UploadMultiFilesDialog
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    FILE_TYPE
  }),

  computed: {
    ...mapState({
      dialog: state => state.files.dialog,
      currentEditProject: state => state.projects.form.currentEditProject,
      fileTree: state => state.files.fileTree,
      storeActiveFileNode: state => state.files.activeFileNode,
      selectedFileNode: state => state.files.selectedFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId',
      getResourceByResourceId: 'projects/getResourceByResourceId',
      folderNodes: 'files/folderNodes',
      hasSelectedFileNode: 'files/hasSelectedFileNode',
      isFileNodeJoined: 'files/isFileNodeJoined',
      isProjectModel: 'files/isProjectModel',
      isDashboardModel: 'files/isDashboardModel'
    }),

    activeFileNode: {
      get() {
        return this.storeActiveFileNode
      },
      set(newVal) {
        this.$store.dispatch('files/activeFileNode', {
          fileNode: newVal,
          open: !!newVal
        })
      }
    },

    project() {
      return this.getProjectByRoute(this.$route)
    },

    isLoading() {
      return this.selectedFileNode?.isLoading
    },

    root() {
      return this.fileTree?.root
    },

    childrenNodes() {
      if (!this.hasSelectedFileNode) {
        return [this.root, ...this.folderNodes]
      }

      const children = this.selectedFileNode.type === FILE_TYPE.FILE
        ? this.selectedFileNode.parent?.children || []
        : this.selectedFileNode.children

      // FIXME: v-list-item-group的v-model沒對應到v-list-item時
      // 會$emit('input', undefined)使active失效
      // 所以給一個不顯示的selectedFileNode在第一個, 避開此issue
      return [this.selectedFileNode, ...children]
    },
    hasChildrenNodes() {
      return this.childrenNodes.length > 1
    },
    roleS3ActiveFileNode() {
      if (!this.project || this.project.isOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.activeFileNode?.uuid) || {}
    },
    roleS3SelectedFileNode() {
      if (!this.project || this.project.isOwner) {
        return roleRoot
      }

      if (this.selectedFileNode) {
        return this.getRoleByResourceId(this.project, this.selectedFileNode.uuid) || {}
      }

      return this.getRoleByResourceId(this.project, this.project?.s3BucketId) || {}
    }
  },

  watch: {
    dialog: {
      handler(newVal) {
        if (newVal && !this.isLoading) {
          this.refreshChildren(this.selectedFileNode || this.root)
        }
      }
    }
  },

  methods: {
    hasResource(resourceId) {
      if (!this.currentEditProject || !resourceId) {
        return
      }

      return !!this.getResourceByResourceId(this.currentEditProject, resourceId)
    },
    refreshChildren(parentNode) {
      if (this.project) {
        this.$store.dispatch('projects/fetchPermissions', {
          projectId: this.project.uuid
        })
      }

      this.fetchChildren(parentNode)
    },
    fetchChildren(parentNode) {
      // 不指定parentNode就是fetch資料夾
      const parent = parentNode || this.root
      const project = this.project
      return this.$store.dispatch('files/fetchFileNodes', {
        project,
        parent
      })
        .catch(error => this.$store.dispatch('snackbar/showError', {
          content: error
        }))
    },
    selectFileNode(fileNode) {
      // 只有資料夾才可以被select, 進到下一層
      if (fileNode.type === FILE_TYPE.FILE) {
        return
      }

      this.activeFileNode = fileNode

      this.$store.dispatch('files/selectFileNode', {
        fileNode
      })

      if (
        fileNode.hasLoaded && // FileTree load過才需要幫忙reload
        fileNode.type !== FILE_TYPE.FILE
      ) {
        this.fetchChildren(fileNode)
      }
    },
    fetchFileData(fileNode) {
      if (
        !this.fetchFileContent ||
        fileNode?.type !== FILE_TYPE.FILE
      ) {
        return
      }

      return this.fetchFileContent(fileNode)
    },
    downloadFile(fileNode) {
      if (fileNode?.type !== FILE_TYPE.FILE) {
        return
      }

      return this.$store.dispatch('files/downloadFile', {
        project: this.project,
        fileNode
      })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.file-list {
  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  &.v-list--dense .v-list-item .v-list-item__title,
  &.v-list--dense .v-list-item .v-list-item__subtitle {
    font-size: 1rem;
    font-weight: normal;
  }

  .v-list-item {
    padding: 0 0.625rem;
    min-height: 30px;

    .v-list-item__content {
      padding: 0;
    }

    .v-list-item__action {
      margin: 0;
    }
  }
}

.spin {
  animation: spin 0.7s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
