<template>
  <v-sheet color="grey lighten-3" height="100%">
    <v-row class="px-8 py-3 bg-ease flex-grow-0" no-gutters>
      <v-col class="step-title white--text">
        {{ $t('page.projects.add_project_grafana_title') }}
      </v-col>
    </v-row>

    <v-row class="my-3" no-gutters style="height: calc(100% - 57px - 24px);">
      <!-- teams col -->
      <v-col
        class="step-col py-4 px-7 white"
        style="flex-basis: 672px;"
      >
        <v-progress-linear
          v-if="isLoadingTeams"
          class="mb-3"
          indeterminate
          color="primary"
        />
        <IssueCard
          v-else-if="hasNoData"
          class="mt-10"
          :message="$t('page.projects.add_team_first')"
          img-width="55%"
        />
        <TeamExpansionPanels
          v-show="!hasNoData"
          :teams="showTeams"
          readonly
        />
      </v-col>

      <!-- joined col -->
      <v-col
        class="step-col white ml-3 pt-4 px-7 d-flex flex-column"
        style="flex-basis: 416px;"
      >
        <div>
          <v-row no-gutters justify="center">
            <h3 style="font-size: 1.375rem;">
              {{ $t('page.projects.manage_grafana_title') }}
            </h3>
          </v-row>

          <v-divider class="my-3" />

          <!-- tip team permission -->
          <v-row no-gutters justify="center">
            <p class="mb-4" style="font-size: 0.875rem;">
              {{ $t('page.projects.manage_grafana_content') }}
            </p>
          </v-row>
        </div>

        <v-card
          color="transparent"
          flat
          style=" overflow-y: auto;height: calc(100% - 114px);"
        >
          <v-row
            v-for="(team, iTeam) in formTeams"
            :key="`${team.name}-${iTeam}`"
            class="mb-2"
            no-gutters
          >
            <TeamRole :team="team" role-type="roleGrafana" />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import TeamExpansionPanels from '@/components/TeamAdder/TeamExpansionPanels'
import TeamRole from './TeamRole'

import { mapState } from 'vuex'

export default {
  name: 'ProjectAdderGrafanaPermission',

  components: {
    IssueCard,
    TeamExpansionPanels,
    TeamRole
  },

  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      formData: state => state.projects.form.formData,
      teams: state => state.teams.teams,
      isLoadingTeams: state => state.teams.isLoadingTeams
    }),

    formTeams() {
      return this.formData.teams
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    showTeams() {
      return this.formTeams.map(
        fTeam => this.teams.find(team => team.name === fTeam.name) || fTeam
      )
    },
    hasNoData() {
      return !this.formTeams.length
    }
  }
}
</script>

<style lang="scss" scoped>
.step-col {
  overflow: auto;
  max-height: 100%;
  border: solid 0.8px #ddd;
}
</style>
