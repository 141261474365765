<template>
  <v-sheet color="grey lighten-3" height="100%">
    <v-row class="px-8 py-3 bg-ease flex-grow-0" no-gutters>
      <v-col class="step-title white--text">
        {{ $t('page.projects.add_project_database_title') }}
      </v-col>
    </v-row>
    <v-row class="my-3" no-gutters style="height: calc(100% - 57px - 24px);">
      <!-- databases col -->
      <v-col
        class="step-col px-7 white"
        style="flex-basis: 672px;"
      >
        <v-row class="mt-3" no-gutters>
          <v-col cols="auto" style="width: 200px;">
            <!-- <v-text-field
              v-model="search"
              class="databases-search"
              outlined
              hide-details
              dense
              prepend-inner-icon="mdi-magnify"
              placeholder="搜尋..."
            ></v-text-field> -->
          </v-col>
        </v-row>

        <!-- database list -->
        <v-list>
          <v-list-item-group v-model="selectedDatabase">
            <v-list-item
              v-for="(database, iDatabase) in databases"
              :key="`${iDatabase}-${database.resourceUuid}`"
              :value="database"
              dense
            >
              <!-- list icon -->
              <v-list-item-icon class="mr-2">
                <v-icon color="primary" small>
                  mdi-chart-donut
                </v-icon>
              </v-list-item-icon>

              <!-- list text -->
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-regular"
                  style="font-size: 1rem;"
                >
                  {{ database.name }}
                </v-list-item-title>
              </v-list-item-content>

              <!-- list action -->
              <v-list-item-action
                v-if="isDatabaseJoined(database)"
                class="primary--text"
                style=" margin: 10px 0;font-size: 0.75rem;"
              >
                <v-row no-gutters>
                  <v-icon color="primary" x-small>
                    mdi-check
                  </v-icon>
                  <span class="ml-1">{{ $t('joined') }}</span>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <!-- teams col -->
      <v-col
        class="step-col white ml-3 pt-4 px-7 d-flex flex-column"
        style="flex-basis: 416px;"
      >
        <div>
          <v-row no-gutters justify="center">
            <h3 style="font-size: 1.375rem;">
              {{ $t('page.projects.manage_database_title') }}
            </h3>
          </v-row>

          <v-divider class="my-3" />

          <!-- tip team permission -->
          <v-row no-gutters justify="center">
            <p class="mb-4" style="font-size: 0.875rem;">
              {{ $t('page.projects.manage_database_content') }}
            </p>
          </v-row>

          <!-- selected database name -->
          <v-row
            v-if="selectedDatabase"
            class="mb-5 primary--text"
            no-gutters
            justify="center"
            style="font-size: 1.125rem;"
          >
            <v-col
              class="text-truncate"
              :title="selectedDatabase.name"
              cols="auto"
            >
              {{ selectedDatabase.name }}
            </v-col>
          </v-row>
        </div>

        <!-- team list -->
        <template v-if="selectedDatabase">
          <v-card
            color="transparent"
            flat
            style=" overflow-y: auto;height: calc(100% - 190px);"
          >
            <v-row
              v-for="(team, iTeam) in selectedDatabaseTeams"
              :key="`${selectedDatabaseTeams.name}-${iTeam}`"
              class="mb-2"
              no-gutters
            >
              <TeamRole v-model="team.role" :team="team" />
            </v-row>
          </v-card>

          <v-btn
            class="add-to-project-btn px-6 py-1"
            :color="isJoined ? 'error' : 'primary'"
            rounded
            outlined
            small
            @click="toggleJoinDatabase()"
          >
            <span v-if="isJoined">
              {{ $t('cancel_joined') }}
            </span>
            <span v-else>
              {{ $t('join_to_project') }}
            </span>
          </v-btn>
        </template>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import TeamRole from './TeamRole'
import { cloneDeep } from 'lodash'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ProjectAdderStep5',

  components: {
    TeamRole
  },

  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    defaultTeams: [],
    selectedDatabase: null
  }),

  computed: {
    ...mapState({
      databases: state =>
        state.databases.databases
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name)),
      isLoadingDatabases: state => state.databases.isLoadingDatabases,
      formData: state => state.projects.form.formData,
      defaultRole: state => state.projects.form.defaultRole
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getDefaultSubTeams: 'projects/form/getDefaultSubTeams'
    }),

    // project() {
    //   return this.getProjectByRoute(this.$route)
    // },
    formTeams() {
      return this.formData.teams
    },

    formDatabases() {
      return this.formData.databases // 設定要被加入到專案的databases
    },
    isJoined() {
      return this.formDatabases?.some(fDatabase => fDatabase.resourceUuid === this.selectedDatabase.resourceUuid)
    },

    selectedDatabaseTeams() {
      if (this.isJoined) {
        return this.formDatabases.find(fDatabase => fDatabase.resourceUuid === this.selectedDatabase.resourceUuid)?.teams || []
      }

      return this.defaultTeams
    }
  },

  watch: {
    isShow: {
      async handler() {
        if (!this.databases.length) {
          await this.fetchDatabases()
        }
      },
      immediate: true
    },
    formTeams: {
      handler() {
        this.initDefaultTeams()
        this.updateFormDatabaseTeams()
      },
      immediate: true
    }
  },

  methods: {
    initDefaultTeams() {
      this.defaultTeams = cloneDeep(this.getDefaultSubTeams)
    },
    fetchDatabases() {
      this.$store.dispatch('databases/fetchDatabases')
    },
    updateFormDatabaseTeams() {
      this.$store.dispatch('projects/form/setFormData', {
        databases: this.formDatabases.map(fDatabase => ({
          ...fDatabase,
          teams: this.defaultTeams.map(defaultTeam => {
            const curTeam = fDatabase.teams.find(team => team.name === defaultTeam.name)

            return {
              ...defaultTeam,
              role: curTeam?.role || defaultTeam.role
            }
          })
        }))
      })
    },
    toggleJoinDatabase() {
      if (this.isJoined) {
        this.$store.dispatch('projects/form/setFormData', {
          databases: this.formDatabases.filter(fDatabase => fDatabase.resourceUuid !== this.selectedDatabase.resourceUuid)
        })

        return
      }

      this.$store.dispatch('projects/form/setFormData', {
        databases: [
          ...this.formDatabases,
          {
            resourceUuid: this.selectedDatabase.resourceUuid,
            teams: cloneDeep(this.defaultTeams)
          }
        ]
      })
      this.initDefaultTeams()
    },
    isDatabaseJoined(database) {
      return this.formDatabases?.find(fDatabase => fDatabase.resourceUuid === database.resourceUuid)
    }
  }
}
</script>

<style lang="scss" scoped>
.step-col {
  overflow: auto;
  max-height: 100%;
  border: solid 0.8px #ddd;
}

.v-input.v-text-field--outlined.databases-search {
  ::v-deep .v-input__slot {
    min-height: 32px;

    fieldset {
      border-color: #eee;
    }

    .v-input__prepend-inner {
      margin-top: 4px;
    }
  }
}

.v-list-item--link.v-list-item--active {
  background-color: rgba(27, 150, 176, 0.15);

  &::before {
    background-color: rgba(27, 150, 176, 0.15);
  }
}

.add-to-project-btn {
  position: absolute;
  right: 30px;
  bottom: 22px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2) !important;
}
</style>
