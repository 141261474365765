<template>
  <v-row v-if="team" class="team-role-row" no-gutters>
    <v-col class="text-truncate">
      <v-row
        class="text-truncate grey lighten-3 pl-3"
        align="center"
        no-gutters
        style="height: 100%;"
      >
        <div class="text-truncate" :title="team.name">
          <v-icon color="primary" small>
            mdi-account-multiple
          </v-icon>
          {{ team.name }}
        </div>
      </v-row>
    </v-col>
    <v-col cols="auto" style="width: 100px;">
      <v-select
        :value="team[roleType]"
        :items="teamRoleOptions"
        class="role-select"
        color="#eee"
        height="30px"
        dense
        hide-details
        outlined
        @change="changeTeamRole($event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TeamRole',

  props: {
    team: {
      type: Object,
      default: null
    },
    roleType: {
      type: String,
      default: 'role'
    }
  },

  data: () => ({
    role: 'viewer'
  }),

  computed: {
    ...mapGetters({
      isEditModel: 'projects/form/editModel',
      storeTeamRoleOptions: 'projects/form/teamRoleOptions'
    }),

    teamRoleOptions() {
      return this.storeTeamRoleOptions(this.isEditModel)
    }
  },
  methods: {
    changeTeamRole(newRole) {
      const keyRole = this.roleType

      if (keyRole === 'roleGrafana') {
        this.team[keyRole] = newRole
      }

      this.$emit('input', newRole)
      this.$emit('update:team', { ...this.team, role: newRole })
    }
  }
}
</script>

<style lang="scss" scoped>
.team-role-row {
  max-width: 100%;
  font-size: 0.875rem;
}

.v-input.v-select.role-select {
  font-size: 0.875rem;
  border-radius: 0;
  outline-color: #eee !important;

  ::v-deep .v-input__slot {
    min-height: 30px;

    fieldset {
      border-color: #eee;
    }

    .v-input__append-inner {
      margin-top: 3px;

      .v-input__icon .v-icon.theme--light {
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }

    .v-select__selection {
      color: $color-primary;
    }
  }
}
</style>
