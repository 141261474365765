<template>
  <v-card
    elevation="0"
    color="transparent"
    dark
    style="position: unset; padding-top: 142px; padding-right: 102px; padding-left: 102px;"
  >
    <h3 class="step-1-title mb-6">
      <span v-if="isEditModel">{{ $t('page.projects.edit_project') }}</span>
      <span v-else>{{ $t('page.projects.add_project_title') }}</span>
    </h3>
    <v-form ref="form" @submit.prevent>
      <v-row no-gutters style="max-width: 340px;">
        <v-col>
          <v-text-field
            v-model.trim="name"
            class="mb-2"
            :placeholder="$t('page.projects.placeholder_project_name')"
            :rules="rulesName"
            solo
            flat
            light
          />
          <v-textarea
            v-model="description"
            class="description-textarea mb-4"
            :placeholder="$t('page.projects.placeholder_project_description')"
            solo
            flat
            light
            hide-details
            auto-grow
          />
        </v-col>
      </v-row>
    </v-form>

    <p class="pl-4">
      {{ $t('page.projects.add_project_content') }}
    </p>

    <!-- <div class="logo-img" /> -->
  </v-card>
</template>

<script>
import { validateRequired, validateDuplicate } from '@/assets/js/validate'

import { mapGetters, mapState } from 'vuex'

const rulesName = vm => [
  validateRequired,
  validateDuplicate(vm.projectNames)
]

export default {
  name: 'ProjectAdderStep1',

  computed: {
    ...mapState({
      formData: state => state.projects.form.formData,
      currentEditProject: state => state.projects.form.currentEditProject
    }),
    ...mapGetters({
      ownerProjects: 'projects/ownerProjects',
      isEditModel: 'projects/form/editModel'
    }),
    rulesName,

    projectNames() {
      const projectNames = this.ownerProjects.map(
        project => project.projectName
      )

      if (this.isEditModel) {
        return projectNames.filter(
          name => name !== this.currentEditProject.projectName
        )
      }

      return projectNames
    },
    name: {
      get() {
        return this.formData.projectName
      },
      set(newVal) {
        this.$store.dispatch('projects/form/setFormData', {
          projectName: newVal
        })
      }
    },
    description: {
      get() {
        return this.formData.projectDesc
      },
      set(newVal) {
        this.$store.dispatch('projects/form/setFormData', {
          projectDesc: newVal
        })
      }
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    init() {
      this.resetValidation()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.step-1-title {
  font-size: 2.25rem;
  font-weight: normal;
}

// .logo-img {
//   width: 208px;
//   height: 112px;
//   background-image: url('../../../assets/images/logo.png');
//   background-size: cover;
//   opacity: 0.35;

//   position: absolute;
//   right: 92px;
//   bottom: 36px;
// }

.description-textarea.v-textarea {
  ::v-deep textarea {
    overflow: auto;
    max-height: 7rem;
  }
}
</style>
