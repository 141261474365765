<template>
  <v-row
    class="file-tool-bar grey lighten-2"
    align="center"
    no-gutters
  >
    <template v-if="roleS3SelectedFileNode.create">
      <AddFolderDialog
        v-if="isFolder"
        :parent="selectedFileNode"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            v-on="on"
          >
            <v-icon>
              $vuetify.icons.add-folder
            </v-icon>
            <span
              v-if="
                !selectedFileNode ||
                  selectedFileNode === fileTree.root
              "
            >{{ $t('page.files.ceate_folder') }}</span>
            <span v-else>{{ $t('page.files.ceate_mapset') }}</span>
          </v-btn>
        </template>
      </AddFolderDialog>
      <!-- <UploadFileDialog
        v-else-if="
          selectedFileNode &&
            selectedFileNode.type===FILE_TYPE.MAPSET
        "
        :parent="selectedFileNode"
      > -->
      <UploadMultiFilesDialog
        v-else-if="
          selectedFileNode &&
            selectedFileNode.type===FILE_TYPE.MAPSET
        "
        :parent="selectedFileNode"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :disabled="
              !selectedFileNode ||
                selectedFileNode.type!== FILE_TYPE.MAPSET
            "
            text
            v-on="on"
          >
            <v-icon>
              mdi-file-upload
            </v-icon>
            {{ $t('page.files.upload_maplayer_title') }}
          </v-btn>
        </template>
      </UploadMultiFilesDialog>
      <!-- </UploadFileDialog> -->
    </template>

    <template v-if="roleS3ActiveFileNode.update">
      <v-divider vertical />
      <!-- <v-btn
        text
        disabled
      >
        <v-icon>mdi-content-cut</v-icon>
        {{ $t('cut') }}
      </v-btn>
      <v-btn
        text
        disabled
      >
        <v-icon>mdi-content-copy</v-icon>
        {{ $t('copy') }}
      </v-btn>
      <v-btn
        text
        disabled
      >
        <v-icon>mdi-content-paste</v-icon>
        {{ $t('paste') }}
      </v-btn> -->
      <v-divider vertical />
      <RenameFileDialog :file-node="activeFileNode">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :disabled="disabledActiveActionBtn"
            text
            v-on="on"
          >
            <v-icon>mdi-rename-box</v-icon>
            {{ $t('rename') }}
          </v-btn>
        </template>
      </RenameFileDialog>
    </template>

    <template v-if="roleS3ActiveFileNode.delete">
      <v-divider vertical />
      <DeleteFileDialog :file-node="activeFileNode">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :disabled="disabledActiveActionBtn"
            text
            v-on="on"
          >
            <v-icon>mdi-delete</v-icon>
            {{ $t('delete') }}
          </v-btn>
        </template>
      </DeleteFileDialog>
    </template>
  </v-row>
</template>

<script>
import AddFolderDialog from './ActionDialogs/AddFolderDialog'
import RenameFileDialog from './ActionDialogs/RenameFileDialog'
import DeleteFileDialog from './ActionDialogs/DeleteFileDialog'
import UploadMultiFilesDialog from './ActionDialogs/UploadMultiFilesDialog'
// import UploadFileDialog from './ActionDialogs/UploadFileDialog'

import { FILE_TYPE } from '@/models/utils'
import { roleRoot } from '@/store/modules/files/files'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'FileToolbar',

  components: {
    AddFolderDialog,
    RenameFileDialog,
    DeleteFileDialog,
    UploadMultiFilesDialog
  },

  data: () => ({
    FILE_TYPE
  }),

  computed: {
    ...mapState({
      fileTree: state => state.files.fileTree,
      selectedFileNode: state => state.files.selectedFileNode,
      activeFileNode: state => state.files.activeFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId'
    }),

    root() {
      return this.fileTree?.root
    },
    isRoot() {
      return !this.selectedFileNode ||
      this.selectedFileNode === this.root
    },
    isFolder() {
      return this.isRoot ||
      this.selectedFileNode.type === FILE_TYPE.FOLDER
    },
    disabledActiveActionBtn() {
      return !this.activeFileNode || this.activeFileNode === this.fileTree.root
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    roleS3ActiveFileNode() {
      if (!this.project || this.project.isOwner) {
        return roleRoot
      }

      return this.getRoleByResourceId(this.project, this.activeFileNode?.uuid) || {}
    },
    roleS3SelectedFileNode() {
      if (!this.project || this.project.isOwner) {
        return roleRoot
      }

      if (this.selectedFileNode) {
        return this.getRoleByResourceId(this.project, this.selectedFileNode.uuid) || {}
      }

      return this.getRoleByResourceId(this.project, this.project?.s3BucketId) || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.file-tool-bar {
  .v-btn > .v-btn__content .v-icon {
    margin-right: 0.15em;
  }
}
</style>
