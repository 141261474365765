<template>
  <v-list class="member-list" dense>
    <v-list-item
      v-for="(member, iMember) in members"
      :key="iMember"
      :class="{
        'px-0': !outlined,
        'mt-1': outlined && iMember > 0,
        'member-list-item--outlined': outlined
      }"
    >
      <v-list-item-icon class="pl-2 mr-2">
        <v-icon color="primary" small>
          mdi-account-circle
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content class="py-0" style="font-size: 0.875rem;">
        <v-row no-gutters style="max-width: 100%;">
          <v-col class="text-truncate" cols="auto" style="width: 84px;">
            {{ member.name }}
          </v-col>

          <v-col class="grey--text text--lighten-1 text-truncate">
            <small>{{ member.email }}</small>
          </v-col>
        </v-row>
      </v-list-item-content>

      <v-list-item-action v-if="!readonly" class="my-0">
        <v-btn
          v-if="!isMemberJoined(member)"
          color="secondary"
          x-small
          rounded
          depressed
          @click="joinMember(member)"
        >
          <v-row align="center" no-gutters>
            <v-icon x-small>
              mdi-plus
            </v-icon>
            {{ $t('join') }}
          </v-row>
        </v-btn>

        <v-row
          v-else
          class="secondary--text mr-3"
          align="center"
          no-gutters
          style="font-size: 0.625rem;"
        >
          <v-icon x-small>
            mdi-check
          </v-icon>
          {{ $t('joined') }}
        </v-row>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MemberList',

  props: {
    outlined: {
      type: Boolean,
      default: false
    },
    members: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      formData: state => state.teams.form.formData,
      isLoadingMembers: state => state.teams.isLoadingMembers
    }),

    formMembers: {
      get() {
        return this.formData.members
      },
      set(newVal) {
        this.$store.dispatch('teams/form/setFormData', {
          members: newVal
        })
      }
    }
  },

  methods: {
    joinMember(member) {
      this.formMembers = [member, ...this.formMembers]
    },
    isMemberJoined(member) {
      return this.formMembers.some(fm => fm.id === member.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.member-list {
  &.v-list--dense {
    .v-list-item {
      min-height: 28px;

      .v-list-item__icon {
        margin-top: 2px;
        margin-bottom: 0;
      }

      &.member-list-item--outlined {
        min-height: 36px;
        border: solid 1px #ddd;

        .v-list-item__icon {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
