<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          color="error"
          :disabled="!fileNode"
          v-on="on"
        >
          {{ $t('delete') }}
        </v-btn>
      </slot>
    </template>
    <v-card class="action-dialog-card">
      <v-card-text class="draggable">
        <v-row
          class="grey--text text--darken-4"
          no-gutters
        >
          <i18n path="confirm_delete_item" tag="span">
            <template v-slot:name>
              <span class="error--text">{{ (fileNode && fileNode.name) || '' }}</span>
            </template>
          </i18n>
        </v-row>
      </v-card-text>

      <v-card-actions class="pb-0">
        <v-spacer />
        <v-btn
          class="action-btn"
          color="primary"
          rounded
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('confirm') }}
        </v-btn>
        <v-btn
          class="action-btn ml-3"
          rounded
          :disabled="isLoading"
          @click="close"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { FileNode } from '@/models'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DeleteFileDialog',

  props: {
    fileNode: { // 要處理的fileNode, 沒給就disabled
      type: FileNode,
      default: null
    }
  },

  data: () => ({
    dialog: false,
    isLoading: false
  }),

  computed: {
    ...mapState({
      activeFileNode: state => state.files.activeFileNode,
      selectedFileNode: state => state.files.selectedFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    }
  },

  methods: {
    close() {
      this.dialog = false
    },
    async submit() {
      this.isLoading = true
      return this.$store.dispatch('files/deleteFile', {
        project: this.project,
        fileNode: this.fileNode
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_file_deleted')
          })

          this.close()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.action-dialog-card {
  padding: 24px 2rem;

  .v-card__subtitle,
  .v-card__text {
    font-size: 1rem;
  }
}

.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
