<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          color="primary"
          :disabled="!fileNode"
          v-on="on"
        >
          {{ $t('rename') }}
        </v-btn>
      </slot>
    </template>
    <v-form
      ref="form"
      v-model="isValid"
      :disabled="!fileNode||isLoading"
      @submit.prevent="submit"
    >
      <v-card class="action-dialog-card">
        <v-card-text>
          <v-row
            class="
            draggable
            grey--text
            text--darken-4
          "
            no-gutters
          >
            {{ $t('rename') }}
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-model="name"
              :placeholder="$t('page.files.placeholder_new_name')"
              :rules="rulesFile"
              autofocus
            />
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-0">
          <v-spacer />
          <v-btn
            class="action-btn"
            color="primary"
            rounded
            :loading="isLoading"
            @click="submit"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            class="action-btn ml-3"
            rounded
            :disabled="isLoading"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { FileNode } from '@/models'
import { FILE_TYPE } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

import { validateFileExtensionName } from '@/assets/js/validate'

export default {
  name: 'RenameFileDialog',

  props: {
    fileNode: { // 要處理的fileNode, 沒給就disabled
      type: FileNode,
      default: null
    }
  },

  data: vm => ({
    dialog: false,
    isValid: true,
    isLoading: false,
    name: vm.fileNode?.name || ''
  }),

  computed: {
    ...mapState({
      asseptFileType: state => state.files.asseptFileType,
      activeFileNode: state => state.files.activeFileNode,
      selectedFileNode: state => state.files.selectedFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    rulesFile() {
      if (this.fileNode?.type !== FILE_TYPE.FILE) {
        return []
      }

      return [
        validateFileExtensionName(this.asseptFileType.split(',')
          .map(type => type.trim().replaceAll('.', '').toLowerCase()))
      ]
    },

    project() {
      return this.getProjectByRoute(this.$route)
    }
  },

  watch: {
    dialog: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true
    },
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    init() {
      this.name = this.fileNode?.name || ''

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    cancel() {
      this.dialog = false

      this.init()
    },
    async submit() {
      if (!this.isValid) {
        return
      }

      this.isLoading = true
      return this.$store.dispatch('files/renameFileNode', {
        project: this.project,
        fileNode: this.fileNode,
        name: this.name
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_updated')
          })

          this.cancel()
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.action-dialog-card {
  padding: 24px 2rem;

  .v-card__subtitle,
  .v-card__text {
    font-size: 1rem;
  }
}

.v-btn.action-btn {
  padding: 0.286em 1.743em;
  height: unset;
  font-size: 0.875rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}
</style>
