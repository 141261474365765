<template>
  <v-card
    class="d-flex flex-column"
    :loading="isLoadingFolders === true"
    outlined
    height="100%"
    style="overflow: auto;"
  >
    <template v-if="isLoadingFolders === true" />

    <v-row v-else-if="typeof isLoadingFolders === 'string'"
           justify="center"
           align="center"
           no-gutters
           style="height: 100%;"
    >
      <IssueCard
        error
        img-width="40%"
        style="width: 100%;"
      >
        <template v-slot:message>
          <v-row no-gutters>
            <v-col class="d-flex justify-center align-center mb-4" cols="12">
              {{ isLoadingFolders }}
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="12">
              <v-btn
                class="text-decoration-underline"
                color="primary"
                text
                :loading="isLoadingFolders === true"
                @click="fetchFolders()"
              >
                {{ $t('reload') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </IssueCard>
    </v-row>

    <template v-else-if="hasFolderNodes">
      <FileBreadcrumbs class="flex-grow-0" divider=">" />
      <FileToolbar v-if="!readonly" class="flex-grow-0" />
      <FileList class="flex-grow-1" :readonly="readonly" />
    </template>

    <FileIssueCard v-else>
      <v-row justify="center">
        <v-col class="d-flex justify-center align-center" cols="12">
          <span v-if="readonly">{{ $t('page.files.no_folder') }}</span>
          <span v-else>{{ $t('page.files.no_folder_and_create') }}</span>
        </v-col>
        <v-col class="d-flex justify-center align-center" cols="12">
          <v-btn v-if="readonly" color="primary" :to="{ name: 'FileMgr' }">
            {{ $t('go_to_create') }}
            <v-icon small right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
          <AddFolderDialog v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" color="primary" v-on="on">
                {{ $t('page.files.add_folder_title') }}
              </v-btn>
            </template>
          </AddFolderDialog>
        </v-col>
      </v-row>
    </FileIssueCard>
  </v-card>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import FileBreadcrumbs from '../Functions/FileBreadcrumbs'
import FileToolbar from '../Functions/FileToolbar'
import FileList from '../Functions/FileList'
import FileIssueCard from '../FileIssueCard'
import AddFolderDialog from '../Functions/ActionDialogs/AddFolderDialog'

import { mapGetters } from 'vuex'

export default {
  name: 'FileViewer',

  components: {
    IssueCard,
    FileBreadcrumbs,
    FileToolbar,
    FileList,
    FileIssueCard,
    AddFolderDialog
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      isLoadingFolders: 'files/isLoadingFolders',
      folderNodes: 'files/folderNodes',
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },
    hasFolderNodes() {
      return !!this.folderNodes.length
    }
  },

  methods: {
    fetchFolders() {
      // 不指定parentNode就是fetch資料夾
      const project = this.project
      return this.$store
        .dispatch('files/fetchFileNodes', {
          project
        })
        .catch((error) =>
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        )
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
