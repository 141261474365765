<template>
  <div>
    <v-row no-gutters justify="center">
      <h3 style="font-size: 1.375rem;">
        {{ $t('page.projects.manage_team_title') }}
      </h3>
    </v-row>

    <v-divider class="mt-3 mb-6" />

    <v-hover
      v-for="(team, iTeam) in teams"
      v-slot="{ hover }"
      :key="`${team.name}-${iTeam}`"
    >
      <v-row class="team-row" align="center" no-gutters>
        <v-col cols="auto">
          <v-icon class="mb-1" color="primary" small>
            mdi-account-multiple
          </v-icon>
        </v-col>
        <v-col class="ml-2 text-truncate" :title="team.name">
          <span>{{ team.name }}</span>
        </v-col>
        <v-expand-x-transition>
          <div
            v-if="hover"
            class="team-remove d-flex transition-fast-in-fast-out white"
            style="height: 100%;"
          >
            <v-btn color="error" height="100%" text @click="remove(team)">
              {{ $t('cancel_joined') }}
            </v-btn>
          </div>
        </v-expand-x-transition>
      </v-row>
    </v-hover>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProjectJoinedTeams',

  computed: {
    ...mapState({
      formData: state => state.projects.form.formData
    }),

    teams: {
      get() {
        return this.formData.teams
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
      },
      set(newVal) {
        this.$store.dispatch('projects/form/setFormData', {
          teams: newVal
        })
      }
    }
  },

  methods: {
    remove(team) {
      this.teams = this.teams.filter(t => t.name !== team.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.team-row {
  position: relative;
  padding: 5px 12px;
  color: #4a4e58;
  border: 1px solid #ddd;
  border-radius: 2px;

  &:not(:first-child) {
    margin-top: 4px;
  }

  .team-remove {
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.16);
  }
}
</style>
