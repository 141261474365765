<template>
  <v-row>
    <v-overlay :opacity="0.9" :value="isLoadingProject">
      <v-progress-circular indeterminate size="84">
        {{ $t('loading') }}...
      </v-progress-circular>
    </v-overlay>

    <v-col
      v-for="project in projects"
      :key="project.uuid"
      cols="auto"
      style="width: 100%;"
    >
      <v-menu absolute offset-y style="max-width: 600px;">
        <template v-slot:activator="{ on, attrs }">
          <v-card
            outlined
            height="210"
            style="box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 1);"
          >
            <v-card-title class="px-3">
              <v-row class="text-truncate" no-gutters>
                <v-col class="mr-3" cols="auto">
                  <v-icon size="40" color="primary">
                    mdi-file-table-box-multiple
                  </v-icon>
                </v-col>
                <v-col class="text-truncate font-weight-bold h3">
                  <v-list-item style="min-height: unset;">
                    <v-list-item-content class="py-0">
                      <v-list-item-title :title="project.projectName">
                        {{ project.projectName }}
                      </v-list-item-title>
                      <v-tooltip bottom>
                        <template
                          v-slot:activator="{
                            on: onTooltip,
                            attrs: attrsTooltip
                          }"
                        >
                          <v-list-item-subtitle
                            class="font-weight-regular"
                            v-bind="attrsTooltip"
                            style="cursor: pointer;"
                            v-on="onTooltip"
                            @click="copy(project.uuid)"
                          >
                            {{ project.uuid }}
                          </v-list-item-subtitle>
                        </template>
                        <span>{{ $t('click_to_copy_uuid') }}</span>
                      </v-tooltip>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-bind="attrs"
                    color="#343843"
                    icon
                    style="position: absolute; top: 4px; right: 4px;"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text class="text-left">
              <div class="truncate" style="color: #343843;">
                {{ project.projectDesc }}
              </div>
            </v-card-text>
          </v-card>
        </template>

        <v-list dense min-width="180">
          <div v-for="(menuItem, iMenuItem) in menuItems" :key="iMenuItem">
            <v-divider v-if="menuItem.divider" />
            <v-list-item
              v-else-if="!menuItem.ownerOnly || project.isOwner"
              @click="menuItem.action(project)"
            >
              <v-list-item-icon v-if="menuItem.icon" class="mr-3">
                <v-icon color="grey darken-3" size="18">
                  {{ menuItem.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="menuItem.title" class="text-left">
                {{ menuItem.title }}
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import copyText from 'clipboard-copy'

import { apiProjectProjects } from '@/api/project'

export default {
  name: 'ProjectList',

  components: {},

  props: {
    projects: {
      type: Array,
      default: () => []
    }
  },

  data: (vm) => ({
    isLoadingProject: false
  }),

  computed: {
    menuItems() {
      return [
        {
          title: this.$t('page.projects.go_to_project'),
          icon: 'mdi-arrow-right',
          action: this.openProject
        },
        {
          title: this.$t('page.projects.edit_project'),
          ownerOnly: true,
          icon: 'mdi-pencil',
          action: this.editProject
        },
        { divider: true },
        {
          title: this.$t('page.projects.go_to_dashboards'),
          icon: 'mdi-view-dashboard',
          action: this.editGraph
        },
        { divider: true },
        {
          title: this.$t('page.projects.delete_project'),
          ownerOnly: true,
          icon: 'mdi-delete',
          action: this.removeProject
        }
      ]
    },
    organizedProjects() {
      return this.projects.reduce((accumulator, project, idx) => {
        if (idx % 6) {
          accumulator.push([])
        }
        accumulator[accumulator.length - 1].push(project)
      }, [])
    }
  },

  methods: {
    copy(text) {
      return copyText(text)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_copied')
          })
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    },
    async removeProject(project) {
      if (confirm(this.$t('confirm_delete'))) {
        apiProjectProjects
          .delete(project.uuid, {
            project_uuid: project.uuid,
            version: project.version
          })
          .then((res) => {
            const data = res?.data?.data
            if (data.result === 'outdate version') {
              this.$store.dispatch('snackbar/showError', {
                content: this.$t('page.projects.version_changed')
              })
            } else {
              this.$store.dispatch('snackbar/showSuccess', {
                content: this.$t('success_deleted')
              })
            }
            this.$emit('reloadProjects')
          })
      }
    },
    openProject(project) {
      this.$router.push({
        name: 'Map',
        params: {
          projectId: project.uuid
        },
        query: this.$route.query
      })
      // const routeData = this.$router.resolve({
      //   name: 'Map',
      //   params: {
      //     projectId: project.uuid
      //   }
      // })
      // window.open(routeData.href, '_blank')
    },
    editProject(project) {
      this.isLoadingProject = true
      Promise.all([
        this.$store.dispatch('projects/fetchPermissions', {
          projectId: project.uuid
        }),
        this.$store.dispatch('teams/fetchTeams'),
        this.$store.dispatch('databases/fetchDatabases')
      ])
        .then((results) => {
          const [permissions] = results
          this.$emit('editProject', {
            ...project,
            permissions: (Array.isArray(permissions) && permissions) || []
          })
        })
        .finally(() => {
          this.isLoadingProject = false
        })
    },
    editGraph(project) {
      this.$router.push({
        name: 'Dashboards',
        params: {
          projectId: project.uuid
        },
        query: this.$route.query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.truncate {
  @include multi-line-trancate(4);

  white-space: normal;
}
</style>
