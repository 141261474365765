<template>
  <v-sheet color="grey lighten-3" height="100%">
    <v-row
      ref="title"
      class="px-8 py-3 bg-ease flex-grow-0"
      no-gutters
    >
      <v-col class="step-title white--text">
        {{ $t('page.projects.add_project_folder_title') }}
      </v-col>
    </v-row>
    <FileManagerProject ref="content" />
  </v-sheet>
</template>

<script>
import FileManagerProject from '@/components/FileManager/FileManagerProject'

export default {
  name: 'ProjectAdderStep4',

  components: {
    FileManagerProject
  },

  data: () => ({
    timerDetectHeight: null
  }),

  mounted() {
    this.setContentMaxHeight()
  },

  beforeDestroy() {
    clearTimeout(this.timerDetectHeight)
  },

  methods: {
    setContentMaxHeight() {
      const title = this.$refs.title
      const content = this.$refs.content?.$el

      if (title && content) {
        const titleHeight = title?.getBoundingClientRect?.()?.height

        if (titleHeight && !Number.isNaN(titleHeight)) {
          content.style.height = `calc(100% - ${titleHeight}px)`
        } else {
          content.style.height = '100%'
        }
      }

      clearTimeout(this.timerDetectHeight)
      this.timerDetectHeight = setTimeout(this.setContentMaxHeight, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.step-col {
  overflow: auto;
  max-height: 100%;
  border: solid 0.8px #ddd;
}

.v-input.v-text-field--outlined.folders-search {
  ::v-deep .v-input__slot {
    min-height: 32px;

    fieldset {
      border-color: #eee;
    }

    .v-input__prepend-inner {
      margin-top: 4px;
    }
  }
}
</style>
